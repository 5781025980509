import React from 'react';

const IconAccount = ({size}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M23.4545455,17.527263 C25.5175273,15.8727198 26.72,13.3672063 26.72,10.7234265 C26.72,5.93790011 22.7803636,2 17.9927273,2 C13.2050909,2 9.26545455,5.93790011 9.26545455,10.7234265 C9.26545455,13.3672063 10.4679273,15.8727198 12.5309091,17.527263 C6.22705455,19.8191979 2.00450909,25.8411246 2,32.5460956 L2,34 L34,34 L34,32.5460956 C33.9933091,25.8374898 29.7640727,19.8142546 23.4545455,17.527263 Z M12,11 C12,7.70855 14.70855,5 18,5 C21.29145,5 24,7.70855 24,11 C24,14.29145 21.29145,17 18,17 C14.70855,17 12,14.29145 12,11 Z M5,31 C5.73204025,24.2021233 11.3677473,19 18,19 C24.6322527,19 30.2679598,24.2021233 31,31 L5,31 Z" id="Fill-1" fill="currentColor"/>
            </g>
        </svg>
    );
};

export default IconAccount;
