import { createSlice } from '@reduxjs/toolkit';

import { login } from './user.slice';

function initialState(diff) {
    return {
        loginVisible: false,
        localesVisible: false,
        optionsVisible: false,
        ...diff
    };
}

export const serviceNavbarStore = createSlice({
    name: 'serviceNavbar',
    initialState: initialState({}),
    reducers: {
        setLoginVisibility(state, action) {
            return initialState({ loginVisible: action.payload })
        },
        setLocaleVisibility(state, action) {
            return initialState({ localesVisible: action.payload })
        },
        setOptionsVisibility(state, action) {
            return initialState({ optionsVisible: action.payload })
        }
    },
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            return initialState();
        }
    }
});

export const {
    setLoginVisibility,
    setLocaleVisibility,
    setOptionsVisibility
} = serviceNavbarStore.actions;