import { createSlice } from '@reduxjs/toolkit';

import { login, logout, recoverPassword } from './user.slice';

function initialState() {
    return {
        recovery: false,
        email: "",
        password: "",
        errors: {},
    }
}

export const loginFormStore = createSlice({
    name: 'loginForm',
    initialState: initialState(),
    reducers: {
        setLoginFormData(state, action) {
            const { email, password } = action.payload;
            state.email = email;
            state.password = password;
        },

        showRecoverPassword(state, action) {
            state.recovery = action.payload;
            state.errors = {}
        }
    },
    extraReducers: {
        [login.fulfilled]: (state) => {
            return initialState();
        },
        [login.rejected]: (state, action) => {
            const { status, data } = action.payload;
            if (status === 401) {
                state.errors = {
                    general: "Bad username or password"
                }
            } else if (status === 422) {
                state.errors = Object.keys(data.errors).reduce((acc, k) => {
                    return {...acc, [k]: data.errors[k][0]};
                }, {});
            } else if (status >= 500) {
                state.errors = {
                    general: "Internal server error"
                }
            }
        }
    }
});

export const {
    setLoginFormData,
    showRecoverPassword
} = loginFormStore.actions;
