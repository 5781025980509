import Axios from 'axios';
import { invalidateCSRF, fetchCSRFToken } from './core/store/csfrToken.slice';
import store from './core/store';

Axios.interceptors.request.use((cfg) => {
    const method = cfg.method.toLowerCase();
    if (['post', 'put', 'patch'].indexOf(method) !== -1) {
        const { token } = store.getState().csrfToken;
        if (token) {
            cfg.data['_token'] = token;
        }
    }
    return cfg;
});

Axios.interceptors.response.use(res => res, (err) => {
    if (err.isAxiosError) {
        const status = err.response.status;
        // if token has expired...
        if (status === 419) {
            store.dispatch(invalidateCSRF());
            store.dispatch(fetchCSRFToken());
        }
    }
    return Promise.reject(err);
});

// hacky solution
const TOKEN_REFETCH_DELAY = 5 * 60 * 1000; // 5min
setInterval(() => {
    store.dispatch(fetchCSRFToken());
}, TOKEN_REFETCH_DELAY);
