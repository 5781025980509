import React from 'react';

const IconArrowUp = ({size}) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 36 36"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        fillRule = "evenodd"
        clipRule = "evenodd"
        strokeLinejoin = "round"
        strokeMiterlimit = "2"
        fill = "currentColor"
      >
        <g id="Artboard">
          <path id="Fill-1" d="M35,26l-4.128,0l-13.332,-12.069l-12.309,11.501l-4.231,-0l16.579,-15.432l17.421,16Z"
                fillRule = "nonzero"
          />
        </g>
      </svg>
    );
};

export default IconArrowUp;
