import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';
import { serializeError } from 'serialize-error';

const URLS = {
    login: '/user/login',
    logout: '/user/logout',
    recoverPassword: '/user/recoverPassword',
    userData: '/user/fetchData',
    invite: '/invite',
    sendReminder: '/user/remind',
    userUpdateDelegation: '/delegation',
    setChatToken: '/user/chat-token',
};

export const setChatToken = createAsyncThunk(
    'user/setChatToken',
    async (chatToken, { dispatch,rejectWithValue }) => {
        try {
            await Axios.post(URLS.setChatToken, {
                chat_token:chatToken,
            });
            dispatch(fetchUserData());
        } catch (err) {
            if (err.isAxiosError) {
                return rejectWithValue(serializeError(err.response));
            }
            throw err;
        }
    }
);

export const login = createAsyncThunk(
    'user/login',
    async ({ email, password }, { dispatch, rejectWithValue }) => {
        try {
            await Axios.post(URLS.login, {
                email,
                password
            });
            dispatch(fetchUserData());
        } catch (err) {
            if (err.isAxiosError) {
                return rejectWithValue(serializeError(err.response));
            }
            throw err;
        }
    }
);

export const logout = createAsyncThunk(
    'user/logout',
    async (args, { rejectWithValue }) => {
        try {
            await Axios.post(URLS.logout, {});
        } catch (err) {
            if (err.isAxiosError) {
                return rejectWithValue(serializeError(err.response));
            }
            throw err;
        }
    }
);

export const recoverPassword = createAsyncThunk(
    'user/recoverPassword',
    async ({ email }, { rejectWithValue }) => {
        try {
            await Axios.post(URLS.recoverPassword, { email });
        } catch (err) {
            if (err.isAxiosError) {
                return rejectWithValue(serializeError(err.response));
            }
            throw err;
        }
    }
);

export const fetchUserData = createAsyncThunk(
    'user/fetchUserData',
    async (args, { rejectWithValue }) => {
        try {
            const res = await Axios.get(URLS.userData);
            return res.data;
        } catch (err) {
            if (err.isAxiosError) {
                return rejectWithValue(serializeError(err.response));
            }
            throw err;
        }
    }
);

export const invite = createAsyncThunk(
    'user/invite',
    async ({id, invitation}, { rejectWithValue }) => {
        const {
            first_name,
            last_name,
            email,
            bfug_participant,
            type, // 'DE' delegato, 'MI' ministro
        } = invitation;
        try {
            const res = await Axios.post(URLS.invite, {
                first_name,
                last_name,
                email,
                bfug: bfug_participant,
                type
            });
            return {id, data: res.data};
        } catch (err) {
            const error = serializeError(err);
            if (err.isAxiosError) {
                return rejectWithValue({id, error});
            }
            throw err;
        }
    }
);

export const submitEditInvite = createAsyncThunk(
    'user/submitEditInvite',
    async ({id, invitation}, { rejectWithValue }) => {
        const {
            first_name,
            last_name,
            bfug_participant,
            type, // 'DE' delegato, 'MI' ministro
        } = invitation;
        try {
            const res = await Axios.post(`/invite/${id}`, {
                first_name,
                last_name,
                bfug: bfug_participant,
                type
            });
            return {id, data: res.data};
        } catch (err) {
            const error = serializeError(err);
            if (err.isAxiosError) {
                return rejectWithValue({id, error});
            }
            throw err;
        }
    }
);

export const deleteInvitation = createAsyncThunk(
    'user/deleteInvitation',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const res = await Axios.delete(`${URLS.invite}/${id}`);
            // or maybe...
            return dispatch(fetchUserData());
            // return res.data;
        } catch (err) {
            if (err.isAxiosError) {
                return rejectWithValue(serializeError(err.response));
            }
            throw err;
        }
    }
);

export const sendReminder = createAsyncThunk(
    'user/sendReminder',
    async (id, { rejectWithValue }) => {
        try {
            const res = await Axios.post(`${URLS.invite}/${id}/remind`, id);
            return res.data;
        } catch (err) {
            if (err.isAxiosError) {
                return rejectWithValue(serializeError(err.response));
            }
            throw err;
        }
    }
);

export const updateDelegation = createAsyncThunk(
    'user/updateDelegation',
    async (args, { rejectWithValue }) => {
        const {
            tech_resp_first_name,
            tech_resp_last_name,
            tech_resp_email,
            tech_resp_phone_prefix,
            tech_resp_phone_number,
            shipping_country,
            shipping_province,
            shipping_city,
            shipping_zipcode,
            shipping_address,
            shipping_notes,
            shipping_phone_prefix,
            shipping_phone_number,
            shipping_ref_name,
            shipping_institution_name
        } = args;
        // const shipping_phone = `${shipping_phone_prefix}${shipping_phone_number}`;
        // const tech_resp_phone = `${tech_resp_phone_prefix}${tech_resp_phone_number}`;
        try {
            const res = await Axios.post(URLS.userUpdateDelegation, {
                tech_resp_first_name,
                tech_resp_last_name,
                tech_resp_email,
                tech_resp_phone_prefix,
                tech_resp_phone_number,
                shipping_country,
                shipping_province,
                shipping_city,
                shipping_zipcode,
                shipping_address,
                shipping_notes,
                shipping_phone_prefix,
                shipping_phone_number,
                shipping_ref_name,
                shipping_institution_name
            });
            return res.data;
        } catch (err) {
            if (err.isAxiosError) {
                return rejectWithValue(serializeError(err));
            }
            throw err;
        }
    }
);

export const editUser = createAsyncThunk(
    'user/edit',
    async ({formData}, { rejectWithValue }) => {
        try {
            const res = await Axios({
                url: `/user/edit`,
                method: 'post',
                headers: { 'Content-Type': 'multipart/form-data' },
                data: formData
            });
            return res.data
        } catch (err) {
            if (err.isAxiosError) {
                const sr = serializeError(err);
                if(err.response.status===413 || err.response.status===500) {
                  sr.response.data = {
                    "message":"System error.",
                    "errors":{
                      "system":[
                        "We are sorry, a system error occurred"
                      ]
                    }
                  };
                }
                return rejectWithValue(sr);
            }
            throw err;
        }
    }
);

export const editDelegate = createAsyncThunk(
    'user/editDelegate',
    async ({id, formData}, { rejectWithValue }) => {
        try {
            const res = await Axios({
                url: `/user/edit/${id}`,
                method: 'post',
                headers: { 'Content-Type': 'multipart/form-data' },
                data: formData
            });
            return res.data
        } catch (err) {
            if (err.isAxiosError) {
                return rejectWithValue(serializeError(err));
            }
            throw err;
        }
    }
);

export const updateChatParticipant = createAsyncThunk(
    'user/updateChatParticipant',
    async (val, { rejectWithValue }) => {
        try {
            const res = await Axios.post("/user/updateChatParticipant", {
                chat_participant: val
            });
            return res.data;
        } catch (err) {
            if (err.isAxiosError) {
                return rejectWithValue(serializeError(err.response));
            }
            throw err;
        }
    }
);

function initialState() {
    return {
        fetchedOnce: false,
        logged: false,
        id: null,

        // data
        first_name: "",
        last_name: "",
        email: "",
        status: "",
        password: "",
        type: "",
        gender: "",
        birth_date: "",
        nationality: "",
        organization: "",
        country_of_residence: "",
        job_title: "",
        phone_prefix: "",
        phone_number: "",
        document_type: "",
        document_code: "",
        document_released_by: "",
        document_expires_at: "",
        document_upload: "",
        profile_photo: "",
        chat_enabled: "",
        chat_participant: "",
        has_shipping: "",
        shipping_phone_prefix: "",
        shipping_phone_number: "",
        shipping_country: "",
        shipping_province: "",
        shipping_city: "",
        shipping_zipcode: "",
        shipping_address: "",
        shipping_notes: "",
        origin: "",
        reset_token: "",
        reset_token_expires_at: "",
        bfug_participant: "",
        bfug: "", // form only
        delegation: {
            is_owner: 0,
            max_ministers: 0,
            max_delegates: 0,
            max_bfug_meeting: 0,
            status: "",
            country: "",
            tech_resp_first_name: "",
            tech_resp_last_name: "",
            tech_resp_email: "",
            tech_resp_phone: "",
            shipping_province: "",
            shipping_city: "",
            shipping_zipcode: "",
            shipping_address: "",
            shipping_notes: "",
            shipping_phone: "", // concatenare prefix e numero
            shipping_country: "",
            shipping_ref_name: "",
            shipping_institution_name: "",
            users: [
                // {
                //     id: "",
                //     first_name: "",
                //     last_name: "",
                //     email: "",
                //     bfug: "",
                //     status: "", // vedi app/User.php:30
                //     // store status
                //     inviteEditing: false
                // }
            ]
        },

        // not found in App/User.php
        issuing_institution: "",

        // todo: check with vale
        isDelegationSubmitted: false,

        // errors
        error: null,
    };
}

function resetState() {
    const { fetchedOnce, ...rest } = initialState();
    return rest;
}


export const userStore = createSlice({
    name: 'user',
    initialState: initialState(),
    reducers: {
        setInviteEditing(state, action) {
            const {id, edit} = action.payload
            const inv = state.delegation.users.find(u => u.id === id);
            inv.inviteEditing = edit;
        }
    },
    extraReducers: {
        [login.fulfilled]: (state) => {
            state.logged = true;
        },
        [login.rejected]: (state, action) => {
            state.logged = false;
            state.error = action.payload;
        },
        [logout.fulfilled]: (state) => {
            return {
                fetchedOnce: state.fetchedOnce,
                ...resetState()
            }
        },
        [logout.rejected]: (state, action) => {
            state.error = action.payload;
        },
        [fetchUserData.fulfilled]: (state, action) => {
            const ret = {
                ...resetState(),
                fetchedOnce: true,
                logged: true,
                ...action.payload
            };
            if (action.payload.delegation) {
                const {delegation:{users}} = action.payload;
                ret.delegation.users = users.map(u => (
                    Object.keys(u).reduce((acc, k) => {
                        if (u[k] === undefined || u[k] === null) {
                            return {...acc, [k]: ""};
                        }
                        return {...acc, [k]: u[k]};
                    },{})
                ));
            }
            return ret;
        },
        [fetchUserData.rejected]: (state, action) => {
            return {
                ...resetState(),
                fetchedOnce: true,
            };
        }
    }
});

export const {
    setInviteEditing
} = userStore.actions;
