import {createSlice} from '@reduxjs/toolkit';

import {updateDelegation} from '../../../core/store/user.slice';

const formFields = [
    "tech_resp_first_name",
    "tech_resp_last_name",
    "tech_resp_email",
    "tech_resp_phone_number",
    "tech_resp_phone_prefix",
    "shipping_institution_name",
    "shipping_country",
    "shipping_province",
    "shipping_city",
    "shipping_zipcode",
    "shipping_address",
    "shipping_notes",
    "shipping_phone_prefix",
    "shipping_phone_number",
    "shipping_ref_name",
];

const initialState = () => {
    return {
        data: formFields.reduce((acc, k) => ({...acc, [k]: ""}), {}),
        errors: {}
    }
};

function flatErrors(errors) {
    return Object.keys(errors).reduce((acc, k) => {
        const e = errors[k];
        if (e.length) {
            return {...acc, [k]: e[0]}
        }
        return acc;
    }, {})
}

export const delegationFormStore = createSlice({
    name: 'delegationForm',
    initialState: initialState(),
    reducers: {
        initForm(state, action) {
            const delegation = action.payload;
            state.errors = {};
            state.data = formFields.reduce((acc, k) => {
                return { ...acc, [k]: delegation[k] || "" };
            }, {});
        },
        setFormData(state, action) {
            state.data = action.payload;
            // ideally, rerun validation
            // pragmatically, nuke errors
            state.errors = {};
        }
    },
    extraReducers: {
        [updateDelegation.fulfilled]: (state, action) => {
            return initialState();
        },
        [updateDelegation.rejected]: (state, action) => {
            console.log(action)
            const {data} = action.payload.response;
            const {message, errors} = data;
            state.errors = flatErrors(errors);
            state.errorMessage = message;
        },
    }
});

export const {
    initForm,
    setFormData,
} = delegationFormStore.actions;

