import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {getPage} from '@js/services/cms-pages';

export const getPageBySlug = createAsyncThunk(
    'cmsPage/getBySlug',
    async (slug,thunkAPI)=>{
        const resp = await getPage.bySlug(slug);
        return resp.data;
    }
);

export const pageStore = createSlice({
    name: 'cmsPage',
    initialState: {
        loaded:false,
        page:{},
        faqSidebar:[],
    },
    reducers: {
        toFaqSidebar(state,action) {
            state.faqSidebar.push(action.payload)
        },
        removeFromSidebar(state,action){
            let toRemove= state.faqSidebar.findIndex(el=> el.id === action.payload)
            state.faqSidebar.splice(toRemove,1);
        }
    },
    extraReducers: {
        [getPageBySlug.pending]:(state,action)=>{
            state.loaded = false;
        },
        [getPageBySlug.fulfilled]:(state,action)=>{

            state.page = {...action.payload};
            state.loaded = true;
        }
    }
});

export const {toFaqSidebar, removeFromSidebar}= pageStore.actions;
