import Axios from 'axios';

const VALID_LANGS = ['it','en','es','fr','de','ru'];
// const VALID_LANGS = ['it','en'];
const DEFAULT_LANG = 'en';

export const api = {
    //aim to get laravel lang translation resource
    getTranslations : async (lang)=>{
        if( !VALID_LANGS.includes(lang)){
            lang = DEFAULT_LANG;
        }
        //check for lang cookie otherwise set it at first render
        if(!checkCookie('lang')){
            await setCookieLang(lang);
        }
        //server will care through middleware to set locale as cookie lang
        const resp = await Axios.get(`/locales/lang-strings`);
        return resp.data;
    },
    // switch locale setting cookie (lang) to mantain same locale if reloaded
    switchLocale : async (lang)=>{
        if( !VALID_LANGS.includes(lang)){
            lang = DEFAULT_LANG;
        }
        await setCookieLang(lang);
        const resp = await Axios.get(`/locales/lang-strings`);
        return resp.data;
    },
};


function checkCookie(name){
    let regex = new RegExp(name);
    let str = decodeURIComponent(document.cookie);
    let splited = str.split(';');
    let res = splited.findIndex(el => regex.test(el));
    return res > -1;
}
function setCookieLang(lang){
    console.log(lang);
    document.cookie = `lang=${lang};SameSite=Lax;path=/`;
    return true;
}
