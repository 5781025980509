import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {settings} from "@js/services/serviceSettings";

export const getHomePage = createAsyncThunk(
    'home/getHome',
    async (slug,thunkAPI)=>{

        const resp = await settings.homePage();
        return resp.data;
    }
);



export const homeStore = createSlice({
    name: 'home',
    initialState: {
        loaded:false,
        settings:{},
    },
    reducers: {
        test(state,payload) {
            state.page = test;
        }
    },
    extraReducers: {
        [getHomePage.pending]:(state,action)=>{
            state.loaded = false;
        },
        [getHomePage.fulfilled]:(state,action)=>{
            state.settings = {...action.payload.settings};
            state.loaded = true;
        },

    }
});

export const {test}= homeStore.actions;
