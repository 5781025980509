import { createSlice } from '@reduxjs/toolkit';

let initialState = {
    headerVisible: true,
    headerConference:false,
}

export const headerNavsStore = createSlice({
    name: 'headerNavs',
    initialState,
    reducers: {
        setHeaderVisibility(state, {payload}) {
            state.headerVisible = payload
        },
        setHeaderConference(state,{payload}){
            state.headerConference = payload;
        }
    },
    extraReducers: {

    }
});

export const {
    setHeaderVisibility,
    setHeaderConference,
} = headerNavsStore.actions;
