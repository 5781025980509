import store from './core/store';

import { getTranslations } from './core/store/locales.slice';

const fromBrowser =()=>{
    const locale = navigator.language;
    return locale.split(/[_-]/)[0]
}

const realLocale = fromBrowser ();
//TODO: default language by now is 'en' instead browser language
store.dispatch(getTranslations('en'));
