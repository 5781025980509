import React,{useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import WipIcon from "./WipIcon";
import {setVisibility} from "../store/videoModal.slice";

const VideoModal = () => {
    let {isOpen,videoSource} = useSelector(s=>s.videoModal);
    const dispatch = useDispatch();

    const handleClose = ()=>{
        document.body.style.overflow = '';
        dispatch(setVisibility(false))
    };

    useEffect(()=>{
        if(isOpen){
            document.body.style.overflow='hidden';
        }
    },[isOpen]);

    const getVideoSource = ()=>{
      return videoSource.startsWith("http://") ? videoSource : `/${videoSource}`;
    }

    return (
        isOpen ?
        <div className="c-video-modal">
            <div className="c-video-modal__close" onClick={handleClose}>
                <WipIcon type={'close'}/>
            </div>
            <div className="c-video-modal__video">
                <video width="100%" height="100%" controls autoPlay>
                    <source src={ getVideoSource() } type="video/mp4" />
                </video>
            </div>
        </div>
        :null
    )

};

export default VideoModal;
