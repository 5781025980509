import {createSlice, createAsyncThunk, current} from '@reduxjs/toolkit';
import { api } from '@js/services/serviceLocales';

export const getTranslations = createAsyncThunk(
    'locale/fetch',
    async (args, {getState})=>{
        const resp = await api.getTranslations(args);
        return resp.data;
    }
);
export const switchLocale = createAsyncThunk(
    'locale/switch',
    async (args, {dispatch})=>{
        const resp = await api.switchLocale(args);
        return resp.data;
    }
);


export const localeStore = createSlice({
    name: 'locale',
    initialState: {
        admitted:[],
        current: null,
        trans:{},
        partnersLogos:[],
    },
    reducers: {
        setCurrent(state,action){
            state.current = action.payload;
        }
    },
    extraReducers: {
        [getTranslations.fulfilled]: (state, action) => {
            state.admitted = [...action.payload.admitted];
            state.current = action.payload.locale;
            state.trans = {...action.payload.translations};
            state.partnersLogos = [...action.payload.translations.partners_logos];
        },
        [switchLocale.fulfilled]: (state, action) => {
            state.current = action.payload.locale;
            state.trans = {...action.payload.translations};
        },
    }
});

export const {
    setCurrent,
} = localeStore.actions;
