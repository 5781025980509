import { createSlice } from '@reduxjs/toolkit';

let initialState = {
    isNavigationVisible:false,
};

export const navbarStore = createSlice({
    name: 'navbar',
    initialState,
    reducers: {
        setNavigationVisibility(state, action) {
            state.isNavigationVisible = action.payload;
        },

    },
    extraReducers: {

    }
});

export const {
  setNavigationVisibility
} = navbarStore.actions;
