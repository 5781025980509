import {createSlice} from '@reduxjs/toolkit';

import {invite} from '../../../core/store/user.slice';

const formFields = [
    "first_name",
    "last_name",
    "email",
    "bfug_participant",
    "type"
];

const createForm = (invite) => {
    return {
       data: formFields.reduce((acc, k) => {
           if (invite[k] !== undefined || invite[k] !== null) {
               return {...acc, [k]: invite[k]};
           }
           return {...acc, [k]: ""};
       }, {}),
       errorMessage: "",
       errors: {}
    }
};

const initialState = () => {
    return {
       // [id]: {
       //     data: formFields.reduce((acc, k) => ({...acc, [k]: ""}), {}),
       //     errorMessage: "",
       //     errors: {}
       // }
    }
};

function flatErrors(errors) {
    return Object.keys(errors).reduce((acc, k) => {
        const e = errors[k];
        if (e.length) {
            return {...acc, [k]: e[0]}
        }
        return acc;
    }, {})
}

export const inviteFormStore = createSlice({
    name: 'inviteForm',
    initialState: initialState(),
    reducers: {
        initForm(state, action) {
            const {formId, invite} = action.payload;
            state[formId] = createForm(invite);
        },
        setFormData(state, action) {
            const { id, data } = action.payload;
            state[id].data = data;
            // ideally, rerun validation
            // pragmatically, nuke errors
            state[id].errors = {};
        }
    },
    extraReducers: {
        [invite.fulfilled]: (state, action) => {
            const { id } = action.payload;
            state[id] = undefined;
        },
        [invite.rejected]: (state, action) => {
            const { id, error } = action.payload;
            const { message, errors } = error.response.data;
            state[id].errorMessage = message;
            state[id].errors = flatErrors(errors);
        },
    }
});

export const {
    initForm,
    setFormData,
} = inviteFormStore.actions;
