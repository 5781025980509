import {createSlice, createAsyncThunk, current} from '@reduxjs/toolkit';
import { api, CHAT_ID } from '@js/services/serviceChatWee';
import {logout, fetchUserData, setChatToken} from './user.slice';

export const registerChatUser = createAsyncThunk(
    'chat/register',
    async (userName,{getState, dispatch})=>{
        console.log(getState().chat.chatId)
        let chatId = getState().chat.chatId;
        let clientKey = getState().chat.clientKey;
        const resp = await api.register(userName,chatId,clientKey);
        console.log('registered user id: ',resp);
        if(resp !==''){
            const r = await dispatch(setChatToken(resp));
            console.log(r);
            // dispatch(loginChatUser(resp));
        }
        console.log('Returning resp from chat/register');
        return resp;
    }
);

export const loginChatUser = createAsyncThunk(
    'chat/login',
    async (userId,{getState,dispatch})=>{
        let chatId = getState().chat.chatId;
        let clientKey = getState().chat.clientKey;
        const resp = await api.login(userId,chatId,clientKey);
        console.log('session login: ',resp);
        if(resp !==''){
            dispatch(chatSession({type:'validate',sessionId:resp}))
        }
        return resp;
    }
);

export const logoutChatUser = createAsyncThunk(
    'chat/logout',
    async ({chatUserId,sessionId},{dispatch})=>{
        const resp = await api.logout(chatUserId);
        console.log('chat/logout: ', resp);
        if(resp){
            dispatch(chatSession({type:'remove',sessionId}));
        }
        return resp;
    }
);

export const chatSession = createAsyncThunk(
    'chat/session',
     async ({type,sessionId},{getState})=>{
         let chatId = getState().chat.chatId;
         let clientKey = getState().chat.clientKey;
         const resp = await api.session(type,sessionId,chatId,clientKey);
         console.log('chat session',type , resp);
         return {
             type,
             resp
         };
    }
)


export const removeChatUser = createAsyncThunk(
    'chat/logout',
    async (userId,{getState, dispatch})=>{
        let chatId = getState().chat.chatId;
        let clientKey = getState().chat.clientKey;
        const resp = await api.remove(userId,chatId,clientKey);
        console.log('User:',userId, 'Was removed:',resp);
    }
)

let initialState = ()=>{
    return {
        chatId:'',
        clientKey:'',
        participant: false,
        loggedIn:false,
        validSession:false,
        currentUser:'',
        sessionId:'',
        chatUserId:'',
        chatManager:false,
        chatOpen:false,
        ipException:false,
    }
}

export const chatWeeStore = createSlice({
    name: 'chat',
    initialState: initialState(),
    reducers: {
        setChatManager(state,action){
            console.log(action);
            state.chatManager = action.payload;
        },
        setChatOpen(state,action){
            state.chatOpen = action.payload;
        }
    },
    extraReducers: {
        [registerChatUser.fulfilled]: (state, action) => {
            // state.userId = action.payload;
        },
        [chatSession.fulfilled] : (state, {payload})=>{
            let {type,resp} = payload;
            let validSession = Boolean(type === 'validate' && resp);
            let hostName = window.location.hostname;
            if(validSession){
                document.cookie = `chatwee-SID-${state.chatId}=${state.sessionId};Domain=${hostName};SameSite=Lax;path=/`;
            }
            state.validSession = validSession;
        },
        [loginChatUser.fulfilled] : (state,action)=>{
            state.loggedIn = true;
            state.sessionId = action.payload;
        },
        [logoutChatUser.fulfilled] : (state,action) =>{
            console.log('logout from chat', action.payload);
            state.loggedIn = false;
        },
        [logout.fulfilled]: (state,action)=>{

        },
        [fetchUserData.fulfilled]: (state, {payload})=>{
            console.log('FETCHDATA FULLFILLED from chat',payload.chat_participant);
            state.enabled = Boolean(payload.chat_enabled);
            state.participant = payload.chat_participant > 0;
            state.chatUserId = payload.chat_token;
            state.chatId = payload.chat_id;
            state.clientKey = payload.chat_api_key;
            state.ipException = payload.ip_exception;
        }

    }
});

export const {
    setChatManager,
    setChatOpen,
} = chatWeeStore.actions;
