import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

import {editUser, editDelegate} from '../../../core/store/user.slice';

const formFields = [
    "first_name",
    "email",
    "last_name",
    "gender",
    "birth_date",
    "nationality",
    "organization",
    "country_of_residence",
    "job_title",
    "phone_prefix",
    "phone_number",
    "document_type",
    "document_code",
    "document_released_by",
    "document_expires_at",
    "document_upload_file",
    "profile_photo_preview",
    "profile_photo_file",
    "chat_participant",
    "shipping_province",
    "shipping_city",
    "shipping_zipcode",
    "shipping_address",
    "shipping_notes",
    "shipping_phone_prefix",
    "shipping_phone_number",
    "shipping_contact_name",
    "shipping_country",
    "bfug",
];

const initialState = () => {
    return {
        data: formFields.reduce((acc, k) => ({...acc, [k]: ""}), {}),
        errorMessage: {},
        errors: {}
    }
};

function flatErrors(errors) {
    return Object.keys(errors).reduce((acc, k) => {
        const e = errors[k];
        if (e.length) {
            return {...acc, [k]: e[0]}
        }
        return acc;
    }, {})
}

export const profileFormStore = createSlice({
    name: 'profileForm',
    initialState: initialState(),
    reducers: {
        initForm(state, action) {
            const profile = action.payload;
            state.errors = {};
            state.data = formFields.reduce((acc, k) => {
                if (k === "chat_participant") {
                    return {...acc, [k]: String(Boolean(profile[k]))};
                }
                return { ...acc, [k]: profile[k] || "" };
            }, {});
            state.data.bfug = profile.bfug_participant;
            state.data.profile_photo_preview = profile.profile_photo;
        },
        setFormData(state, action) {
            state.data = action.payload;
            // ideally, rerun validation
            // pragmatically, nuke errors
            state.errors = {};
        },
    },
    extraReducers: {
        [editUser.fulfilled]: (state, action) => {
            return initialState();
        },
        [editDelegate.fulfilled]: (state, action) => {
            return initialState();
        },
        [editUser.rejected]: (state, action) => {
            const { message, errors} = action.payload.response.data;
            state.errorMessage = message;
            state.errors = flatErrors(errors);
        },
        [editDelegate.rejected]: (state, action) => {
            const { message, errors} = action.payload.response.data;
            state.errorMessage = message;
            state.errors = flatErrors(errors);
        }
    }
});

export const {
    initForm,
    setFormData,
} = profileFormStore.actions;
