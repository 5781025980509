import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {getPage} from '@js/services/cms-pages';
import {settings} from '../../services/serviceSettings';

export const getPhaseBySlug = createAsyncThunk(
    'cmsPhase/getBySlug',
    async (slug)=>{
        let resp;
        switch (slug) {
            case "home":
                resp = await settings.homePage();
                return {
                    slug: "home",
                    phase: resp.data.settings.conference_phase.en
                };
            default:
                resp = await getPage.bySlug(slug);
                return {
                    slug: slug,
                    phase: resp.data.phase
                };
        }
    }
);

export const cmsPhaseStore = createSlice({
    name: 'cmsPhase',
    initialState: {
        // [slug]: "DEFAULT"|"PRE"|"LIVE"|"POST"
    },
    reducers: {},
    extraReducers: {
        [getPhaseBySlug.fulfilled]:(state,action)=>{
            const {slug, phase} = action.payload;
            return { ...state, [slug]: phase || "DEFAULT" };
        }
    }
});

export const {} = cmsPhaseStore.actions;
