import * as THREE from 'three';
import React,{Suspense, useState,useEffect, useRef} from 'react';
import { Canvas, extend, useFrame, useThree, useLoader } from 'react-three-fiber';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

import WipIcon from "./WipIcon";
import {useDispatch} from "react-redux";
import {setOpenViewer} from "../store/img360.slice";

extend({OrbitControls});

function Controls(props) {
    const { camera, gl } = useThree()
    const ref = useRef();
    // let maxPolarA = Math.PI/3;
    useFrame(() => ref.current.update())
    return <orbitControls ref={ref} target={[0, 0, 0]} {...props} args={[camera, gl.domElement]} />
    // return <orbitControls maxPolarAngle={maxPolarA} minPolarAngle={maxPolarA} ref={ref} target={[0, 0, 0]} {...props} args={[camera, gl.domElement]} />
}
function Stage({texture}) {

    return (
        <mesh>
            <sphereBufferGeometry attach="geometry" args={[3, 32, 32]} />
            {/*<cylinderBufferGeometry attach={'geometry'} args={[diameter,diameter,height,64,1,true]} />*/}
            <meshBasicMaterial attach="material" map={texture} side={THREE.BackSide} />
        </mesh>
    )
}

const Img360Viewer = ({id,src}) => {
    const texture = useLoader(THREE.TextureLoader, src)
    texture.wrapS = THREE.RepeatWrapping;
    texture.repeat.x = - 1;

    let ratio = texture.image.width/texture.image.height;

    // let baseH = 500;
    // let cH = baseH * ratio;
    // // let C = cH * ratio;
    //
    // let cD = (cH / Math.PI );
    //
    // let K = cH * 0.01;
    // let D = cD * 0.01;

    let dispatch = useDispatch();
    let refContainer = useRef();
    const closeViewer = ()=>{
        dispatch(setOpenViewer(false));
    }

    return (
        <div className={'c-360-viewer'}>
            <div ref={refContainer} id={`viewer-${id}`} className="viewer__wrapper">
                <div className="viewer__close" onClick={closeViewer}>
                    <WipIcon type={'close'} size={20}/>
                </div>
                <Canvas camera={{position:[0,0,0.1]}} className={'viewer__canvas'}>
                    <Controls enableZoom={false} enablePan={false} enableDamping dampingFactor={0.2} autoRotate autoRotateSpeed={0.5} rotateSpeed={-0.4} />
                    <Suspense fallback={null}>
                        <Stage texture={texture} size={3}/>
                        {/*<Stage texture={texture} diameter={D} height={K}/>*/}
                    </Suspense>
                </Canvas>
            </div>
        </div>
    );
};

export default Img360Viewer;
