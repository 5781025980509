import React from 'react';

const IconExpand = ({size}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="expand-alt-solid" transform="translate(1.000000, 1.000000)" fill="currentColor" fillRule="nonzero">
                    <path d="M15.6550258,21.878034 L8.83275388,28.7003294 L11.2560199,30.9842666 C12.3689469,32.0971974 11.5807708,34 10.0069213,34 L1.76301767,34 C0.787366402,34 0,33.2090978 0,32.2334431 L0,23.9895111 C0,22.4156561 1.8992629,21.6274773 3.01211628,22.7403345 L5.29965233,25.1672156 L12.1219242,18.3449202 C12.5818163,17.8850266 13.3275215,17.8850266 13.7874136,18.3449202 L15.6550258,20.2125389 C16.1149914,20.6724325 16.1149914,21.4180667 15.6550258,21.878034 L15.6550258,21.878034 Z M18.3449742,12.121966 L25.1672461,5.29967061 L22.7439801,3.0157334 C21.6310531,1.90280257 22.4192292,0 23.9930787,0 L32.2369823,0 C33.2126336,0 34,0.790902232 34,1.76655687 L34,10.0104889 C34,11.5843439 32.1007371,12.3725227 30.9878837,11.2596655 L28.7003477,8.83278435 L21.8780758,15.6550798 C21.4181837,16.1149734 20.6724785,16.1149734 20.2125864,15.6550798 L18.3449742,13.7874611 C17.8850086,13.3275675 17.8850086,12.5819333 18.3449742,12.121966 L18.3449742,12.121966 Z" id="Shape"/>
                </g>
            </g>
        </svg>
    );
};

export default IconExpand;
