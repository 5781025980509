import React from 'react';

const IconCaretDown = ({size}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 17 12" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="Page-1" stroke="none" strokeWidth="1" fill="currentColor" fillRule="evenodd">
                <g id="Artboard" transform="translate(-12.000000, -14.000000)" >
                    <polygon points="20.25 25.5 12 14 28.5 14" />
                </g>
            </g>
        </svg>
    );
};

export default IconCaretDown;
