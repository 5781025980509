import React,{useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import WipIcon from "./WipIcon";
import {setContent,setOpenTextModal} from "../store/textModal.slice";

import PerfectScrollbar from 'react-perfect-scrollbar';

const TextModal = () => {
    let theModal = useSelector(s=>s.textModal);
    let {isOpen, title,htmlBody,author,authorRole} = theModal;
    const dispatch = useDispatch();

    const handleClose = ()=>{
        document.body.style.overflow = '';
        dispatch(setOpenTextModal(false))
    };

    useEffect(()=>{
        if(isOpen){
            document.body.style.overflow='hidden';
        }
    },[isOpen]);

    return (
        isOpen ?
        <div className={'c-text-modal'}>
            <div className="text-modal__head">
                <div className="text-modal__close" onClick={handleClose}>
                    <WipIcon type={'close'}/>
                </div>
                <div className="text-modal__title">{title}</div>
            </div>
            <div className="text-modal__wrapper">
                <PerfectScrollbar>
                    <div className="text-modal__content" >
                        <div dangerouslySetInnerHTML={{ __html:htmlBody }} />
                        {author && <div className="text-modal__author">{author}</div>}
                        {authorRole && <div className="text-modal__author-role">{authorRole}</div>}
                    </div>
                </PerfectScrollbar>
            </div>
        </div>
        :null
    )

};

export default TextModal;
