import React from 'react';

const IconBreak = ({size}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g id="Group-6" transform="translate(5.000000, 5.000000)" stroke="currentColor" strokeWidth="2">
                    <path d="M16,7 L17,7 C19.1943,7 21,8.8057 21,11 C21,13.1943 19.1943,15 17,15 L16,15" id="Stroke-1"/>
                    <path d="M0,7 L16,7 L16,16 C16,18.1943 14.1943,20 12,20 L4,20 C1.8057,20 0,18.1943 0,16 L0,7 Z" id="Stroke-2"/>
                    <line x1="4" y1="0" x2="4" y2="3" id="Stroke-3"/>
                    <line x1="8" y1="0" x2="8" y2="3" id="Stroke-4"/>
                    <line x1="12" y1="0" x2="12" y2="3" id="Stroke-5"/>
                </g>
            </g>
        </svg>
    );
};

export default IconBreak;
