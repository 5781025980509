import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import Axios from 'axios';

const TOKEN_URL = "/token"

export const fetchCSRFToken = createAsyncThunk(
    'token/fetch',
    async () => {
        const res = await Axios.get(TOKEN_URL);
        return res.data
    }
);

export const testAjax = createAsyncThunk(
    'token/testAjax',
    async () => {
        const data = {
            type: 'GI',
            email: 'test@test.test',
            password: 'asdlalla',
            first_name: 'test',
            last_name: 'wipitalia',
            gender: 'male',
            birth_date: '2020-01-01',
            nationality: 'ancapistan',
            organization: 'wipitalia',
            country_of_residence: 'ancapistan',
            job_title: 'fashion designer',
            phone_prefix: '+39',
            phone_number: '1231231230',
            document_type: 'CI',
            document_code: 'AT1234567',
            document_released_by: 'Comune di Milano',
            document_expires_at: '2025-01-01',
            origin: 'nether',
            privacy: true,
            document_upload: null,
            profile_photo: null,
        };

        try {
            const res = await Axios.post('/419', data);
        } catch (err) {
            console.log(res.status);
            throw err
        }
        return res.status;
    }
);

// export const testAjax2 = () => (dispatch, getState) => {
//     const data = {
//         type: 'GI',
//         email: 'test@test.test',
//         password: 'asdlalla',
//         first_name: 'test',
//         last_name: 'wipitalia',
//         gender: 'male',
//         birth_date: '2020-01-01',
//         nationality: 'ancapistan',
//         organization: 'wipitalia',
//         country_of_residence: 'ancapistan',
//         job_title: 'fashion designer',
//         phone_prefix: '+39',
//         phone_number: '1231231230',
//         document_type: 'CI',
//         document_code: 'AT1234567',
//         document_released_by: 'Comune di Milano',
//         document_expires_at: '2025-01-01',
//         origin: 'nether',
//         privacy: true,
//         document_upload: null,
//         profile_photo: null,
//     };
//
//     let maxRetry = 3;
//     const doAjax = () => {
//         maxRetry -= 1;
//         Axios.post('/419', data)
//             .then(res => dispatch({
//                 type: 'token/testAjax/fulfilled',
//                 payload: res.data
//             }))
//             .catch(err => {
//                 if (err.response.status === 419) {
//                     dispatch(tokenStore.actions.invalidate());
//                     dispatch(fetchCSRFToken()).then(() => maxRetry >= 0 && doAjax());
//                 } else {
//                     dispatch({
//                         type: 'token/testAjax/rejected',
//                         payload: err
//                     })
//                 }
//             })
//     };
//
//     doAjax();
// }

export const csrfTokenStore = createSlice({
    name: 'token',
    initialState: {
        token: null,
        error: null
    },
    reducers: {
        invalidateCSRF(state) {
            state.token = null;
        }
    },
    extraReducers: {
        [fetchCSRFToken.fulfilled]: (state, action) => {
            state.token = action.payload;
            state.error = null;
        },
        [fetchCSRFToken.rejected]: (state, action) => {
            state.token = null;
            state.error = action.payload;
        }
    }
});

export const {
    invalidateCSRF,
} = csrfTokenStore.actions;
