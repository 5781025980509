import Axios from 'axios';

// const varsEnv = process.env;
export const CHAT_ID = '5fad153345622623c8224826';
const API_KEY = '26e88b1039e970e621c16613';
// export const CHAT_ID = varsEnv.CHAT_ID;
// const API_KEY = varsEnv.CHAT_API_KEY;
const API_USER = 'https://chatwee-api.com/v2/sso-user';

const service = Axios.create({
    baseURL: API_USER,
});
let baseParams = {
    chatId: CHAT_ID,
    clientKey: API_KEY,
}
export  const api = {
    register: async (userName,chatId,clientKey)=>{
        const resp = await service.get('/register',{
            params:{
                chatId,
                clientKey,
                login: userName,
                isAdmin: 0,
            }
        });
        return resp.data;
    },
    login: async (uId,chatId,clientKey)=>{
        const resp = await service.get('/login',{
            params:{
                chatId,
                clientKey,
                userId: uId,
            }
        });
        return resp.data;
    },
    logout: async ({uId,chatId, clientKey})=>{
        const resp = await service.get('/logout',{
            params: {
                chatId,
                clientKey,
                userId: uId,
            }
        });
        return resp.data;
    },
    remove: async ({uId,chatId, clientKey})=>{
        const resp = await service.get('/remove',{
            params: {
                chatId,
                clientKey,
                userId: uId,
            }
        });
        return resp.data;
    },
    session: async (type,sessionId,chatId,clientKey)=>{
        const resp = await service.get(`/${type}-session`,{
            params:{
                chatId,
                clientKey,
                sessionId,
            }
        });
        return resp.data;
    },
}
