import { createSlice } from '@reduxjs/toolkit';

export const carouselStore = createSlice({
    name: 'carousel',
    initialState: {
        // [id]: {
        //     current: -1,
        //     length: 0
        // }
    },
    reducers: {
        carouselReset(state, action) {
            return {};
        },
        carouselInit(state, action) {
            const {id, current, length} = action.payload;
            state[id] = { current, length }
        },
        carouselSet(state, action) {
            const { id, index } = action.payload;
            const car = state[id];
            if (car) {
                const idx = Math.max(0, (Math.min(index, car.length - 1)));
                car.current = idx;
            }
        },
        carouselNext(state, action) {
            const id = action.payload;
            const car = state[id];
            car.current = Math.min(car.current + 1, car.length - 1);
        },
        carouselPrev(state, action) {
            const id = action.payload;
            const car = state[id];
            car.current = Math.max(car.current - 1, 0);
        },
    },
});

export const {
    carouselReset,
    carouselInit,
    carouselSet,
    carouselNext,
    carouselPrev
} = carouselStore.actions;
