import React from 'react';
import { useSelector } from 'react-redux';


function LoginStatusSwitch({children}) {
    const { fetchedOnce, logged } = useSelector(state => state.user);

    const getExcluded = () => {
        const all = Object.keys(LoginStatusSwitch)
           .map(k => LoginStatusSwitch[k]);
        if (!fetchedOnce) {
            return all;
        }
        if (logged) {
            return all.filter(c => c !== LoginStatusSwitch.LoggedIn)
        }
        return all.filter(c => c !== LoginStatusSwitch.Guest)
    };

    const checkChild = (c) => {
        const excluded = getExcluded();
        if (excluded.indexOf(c.type) !== -1) {
            return null;
        }
        return c;
    };

    const mchildren = React.Children
        .map(children, checkChild)
        .filter(c => c !== null);

    return <>{mchildren}</>;
}

LoginStatusSwitch.LoggedIn = function LoginStatusSwitchPre({children}) {
    return <>{children}</>
};

LoginStatusSwitch.Guest = function LoginStatusSwitchEvent({children}) {
    return <>{children}</>
};

export default LoginStatusSwitch;
