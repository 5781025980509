import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import Axios from 'axios'
import {serializeError} from 'serialize-error';

export const sendContact = createAsyncThunk(
    'contactForm/sendContact',
    async (data, { rejectWithValue }) => {
        try {
            await Axios.post('/contact', data);
        } catch (err) {
            if (err.isAxiosError) {
                return rejectWithValue(serializeError(err));
            }
            throw err;
        }
    }
);

const formFields = [
    "first_name",
    "last_name",
    "email",
    "phone_prefix",
    "phone_number",
    "request",
    "privacy",
];

const initialState = () => {
    return {
        data: formFields.reduce((acc, k) => ({...acc, [k]: ""}), {}),
        errors: {}
    }
};

function flatErrors(errors) {
    return Object.keys(errors).reduce((acc, k) => {
        const e = errors[k];
        if (e.length) {
            return {...acc, [k]: e[0]}
        }
        return acc;
    }, {})
}

export const contactFormStore = createSlice({
    name: 'contactForm',
    initialState: initialState(),
    reducers: {
        initForm(state, action) {
            const contact = action.payload;
            state.errors = {};
            state.data = formFields.reduce((acc, k) => {
                return { ...acc, [k]: contact[k] || "" };
            }, {});
        },
        setFormData(state, action) {
            state.data = action.payload;
            // ideally, rerun validation
            // pragmatically, nuke errors
            state.errors = {};
        }
    },
    extraReducers: {
        [sendContact.fulfilled]: (state, action) => {
            return initialState();
        },
        [sendContact.rejected]: (state, action) => {
            const {data} = action.payload.response;
            const {message, errors} = data;
            state.errors = flatErrors(errors);
            state.errorMessage = message;
        },
    }
});

export const {
    initForm,
    setFormData,
} = contactFormStore.actions;

