import React from 'react';

const IconLens = ({size}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="Lens" stroke="none" strokeWidth="1" fill="currentColor" fillRule="evenodd">
                <path d="M5.6849,16.9908 C5.6849,10.2182 11.0281,4.7625 17.661,4.7625 C24.2939,4.7625 29.6371,10.2182 29.6371,16.9908 C29.6371,23.7633 24.2939,29.219 17.661,29.219 C11.0281,29.219 5.6849,23.7633 5.6849,16.9908 Z M37.5597,30.7241 L31.4795,24.5159 C32.585,22.2583 33.322,19.6245 33.322,16.9908 C33.322,8.1488 26.3206,1 17.661,1 C9.0014,1 2,8.1488 2,16.9908 C2,25.8327 9.0014,32.9816 17.661,32.9816 C20.2405,32.9816 22.8199,32.2291 25.0309,31.1003 L31.111,37.3085 C32.9535,39.1897 35.7172,39.1897 37.5597,37.3085 C39.2179,35.4272 39.2179,32.6053 37.5597,30.7241 Z" />
            </g>
        </svg>
    );
};

export default IconLens;
