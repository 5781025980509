import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {getPhaseBySlug} from '../store/cmsPhase.slice';

function CmsPhase({slug, children}) {
    let _children = children;
    if (!children) {
        _children = [];
    } else if (!children.length) {
        _children = [children]
    }

    const dispatch = useDispatch();
    const phase = useSelector(s => s.cmsPhase[slug]);

    useEffect(() => {
        dispatch(getPhaseBySlug(slug));
    }, [slug]);

    let toExclude = [
        (<CmsPhase.Pre/>).type,
        (<CmsPhase.Live/>).type,
        (<CmsPhase.Post/>).type,
        (<CmsPhase.Default/>).type,
    ];
    switch (phase) {
        case undefined:
            // still waiting for ajax
            return null;
        case "PRE":
            toExclude = toExclude.filter(c => c !== (<CmsPhase.Pre/>).type)
            break;
        case "LIVE":
            toExclude = toExclude.filter(c => c !== (<CmsPhase.Live/>).type)
            break;
        case "POST":
            toExclude = toExclude.filter(c => c !== (<CmsPhase.Post/>).type)
            break;
        default:
            toExclude = toExclude.filter(c => c !== (<CmsPhase.Default/>).type)
            break;
    }

    return <> {_children.filter(c => toExclude.indexOf(c.type) === -1)}</>;
}

CmsPhase.Pre = function CmsPhasePre({children}) {
    return <>{children}</>;
};

CmsPhase.Live = function CmsPhasePre({children}) {
    return <>{children}</>;
};

CmsPhase.Post = function CmsPhasePre({children}) {
    return <>{children}</>;
};

CmsPhase.Default = function CmsPhasePre({children}) {
    return <>{children}</>;
};

export default CmsPhase;
