import React from 'react';

const IconCalendar = ({size}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g id="Group-5" transform="translate(6.000000, 5.000000)" stroke="currentColor" strokeWidth="2">
                    <path d="M18,4 C18,2.8962 17.1038,2 16,2 L2,2 C0.8962,2 0,2.8962 0,4 L0,18 C0,19.1038 0.8962,20 2,20 L16,20 C17.1038,20 18,19.1038 18,18 L18,4 Z" id="Stroke-1"/>
                    <line x1="13" y1="0" x2="13" y2="4" id="Stroke-2"/>
                    <line x1="5" y1="0" x2="5" y2="4" id="Stroke-3"/>
                    <line x1="0" y1="8" x2="18" y2="8" id="Stroke-4"/>
                </g>
            </g>
        </svg>
    );
};

export default IconCalendar;
