import React from 'react';

const IconClose = ({size}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <polygon id="Fill-1" fill="currentColor" points="24.0013 2.9168 14.4174 12.5007 24.0013 22.0845 22.0845 24.0013 12.5006 14.4174 2.9168 24.0013 1 22.0845 10.5839 12.5007 1 2.9168 2.9168 1 12.5006 10.5839 22.0845 1"/>
            </g>
        </svg>
    );
};

export default IconClose;
