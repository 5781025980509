import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import { textModalStore } from './textModal.slice'
import { videoModalStore } from './videoModal.slice'
import { localeStore } from './locales.slice';
import { csrfTokenStore } from './csfrToken.slice';
import { countryCodesStore } from './countryCodes.slice';
import { userStore } from './user.slice';
import { phazerStore } from './phazer.slice';
import { loginFormStore } from './loginForm.slice';
import { resetPasswordFormStore } from '../../views/resetPassword/store/resetPasswordForm.slice';
import { splashStore } from '../../views/splash/store/splash.slice';
import { pageStore } from '../../views/cms-page/store/cmsPage.slice';
import { homeStore } from '../../views/home/store/home.slice';
import { serviceNavbarStore } from './serviceNavbar.slice';
import { sidebarStore} from "./sideBar.slice";
import { cookiebarStore} from "./cookiebar.slice";
import { eventsStore} from "./events.slice";
import { profileFormStore } from '../../views/profile/store/profileForm.slice';
import { inviteFormStore } from '../../views/profile/store/inviteForm.slice';
import { delegationFormStore } from '../../views/profile/store/delegationForm.slice';
import { contactFormStore } from '../../views/contact/store/contactForm.slice';
import { headerNavsStore } from "./headerNavs.slice";
import { exhibitionGalleryStore } from "../../views/exhibition/store/exhibitionGallery.store";
import { carouselStore } from "../../views/exhibition/store/carousel.store";
import { cmsPhaseStore } from "./cmsPhase.slice";

import { navbarStore} from "./navbar.slice";
import {conferenceStore} from "./conference.slice";
import {chatWeeStore} from "./chatWee.slice";
import {img360Store} from "./img360.slice";

const reducer = combineReducers({
    locale: localeStore.reducer,
    csrfToken: csrfTokenStore.reducer,
    countryCodes: countryCodesStore.reducer,
    user: userStore.reducer,
    chat: chatWeeStore.reducer,
    phazer: phazerStore.reducer,
    loginForm: loginFormStore.reducer,
    resetPasswordForm: resetPasswordFormStore.reducer,
    splash: splashStore.reducer,
    serviceNavbar: serviceNavbarStore.reducer,
    profileForm: profileFormStore.reducer,
    inviteForm: inviteFormStore.reducer,
    delegationForm: delegationFormStore.reducer,
    contactForm: contactFormStore.reducer,

    cmsPage: pageStore.reducer,
    cmsPhase: cmsPhaseStore.reducer,
    home: homeStore.reducer,
    conference: conferenceStore.reducer,
    events: eventsStore.reducer,
    headerNavs: headerNavsStore.reducer,
    sideBar: sidebarStore.reducer,
    navBar: navbarStore.reducer,
    cookiebar: cookiebarStore.reducer,

    exhibitionGallery: exhibitionGalleryStore.reducer,
    carousel: carouselStore.reducer,
    textModal: textModalStore.reducer,
    videoModal: videoModalStore.reducer,
    img360: img360Store.reducer,
});

const store = configureStore({
    reducer,
    middleware: [...getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
    })],
    devTools: process.env.NODE_ENV !== 'production'
});

export default store;

