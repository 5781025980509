import {createSlice} from '@reduxjs/toolkit';

let initialState = {
    isOpen: false,
    title: '',
    htmlBody:'',
    author:'',
    authorRole:'',
};

export const textModalStore = createSlice({
  name: 'textModal',
  initialState,
  reducers: {
    setContent(state, action){
        let {title, htmlBody,author,authorRole} = action.payload;
        if(title !== ''){
            state.title = title;
        }
        if(htmlBody !==''){
            state.htmlBody = htmlBody;
        }
        if(author !== ''){
            state.author = author;
        }
        if(authorRole !== ''){
            state.authorRole = authorRole;
        }
    },
    setOpenTextModal(state,action){
        if(typeof action.payload === 'boolean'){
            state.isOpen = action.payload;
          }else{
            state.isOpen = false;
        }
    }

  },
  extraReducers: {}
});

export const {
    setContent,
    setOpenTextModal
} = textModalStore.actions;
