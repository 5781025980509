import Axios from 'axios';

export const settings = {
    homePage: async ()=>{
        const resp = await Axios.get(`/api/settings/homepage`);
        return resp.data;
    },
    conference: async ()=>{
        const resp = await Axios.get(`/api/settings/conference-stream`);
        return resp.data;
    }
}
