import React from 'react';

const IconLangPlayer = ({size}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M16.5449219,24.9111328 L16.5449219,23.3730469 L8.70507812,23.3730469 L8.70507812,18.9960938 L15.8330078,18.9960938 L15.8330078,17.5019531 L8.70507812,17.5019531 L8.70507812,13.5820312 L16.4130859,13.5820312 L16.4130859,12 L7,12 L7,24.9111328 L16.5449219,24.9111328 Z M20.5087891,24.9111328 L20.5087891,14.4609375 L27.1269531,24.9111328 L29.0869141,24.9111328 L29.0869141,12 L27.4257812,12 L27.4257812,22.4589844 L20.9042969,12 L18.8388672,12 L18.8388672,24.9111328 L20.5087891,24.9111328 Z" id="EN" fill="currentColor" fillRule="nonzero" />
                <rect id="Rectangle" stroke="currentColor" strokeWidth="2" x="3" y="3" width="30" height="30" />
            </g>
        </svg>
    );
};

export default IconLangPlayer;
