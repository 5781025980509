import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';

export const fetchSomething = createAsyncThunk(
    'splash/fetchSomething',
    async (params) => {
        const res = await Axios.get('http://example.com');
        return res.data
    }
);

export const splashStore = createSlice({
    name: 'splash',
    initialState: {
        testValue: false,
        fetchStatus: 'none'
    },
    reducers: {
        test(state, action) {
            state.testValue = action.payload
        }
    },
    extraReducers: {
        [fetchSomething.pending]: (state, action) => {
            state.fetchStatus = 'pending'
        },
        [fetchSomething.fulfilled]: (state, action) => {
            state.fetchStatus = 'fulfilled'
        },
        [fetchSomething.rejected]: (state, action) => {
            state.fetchStatus = 'rejected'
        }
    }
});

export const {
    test,
} = splashStore.actions;