import {createSlice} from '@reduxjs/toolkit';

let initialState = {
    isOpen: false,
    videoSource: null
};

export const videoModalStore = createSlice({
  name: 'videoModal',
  initialState,
  reducers: {
    setVideo(state, action){
        let {videoSource} = action.payload;
        if(videoSource){
            state.videoSource = videoSource;
        }
    },
    setVisibility(state,action){
        if(typeof action.payload === 'boolean'){
            state.isOpen = action.payload;
          }else{
            state.isOpen = false;
        }
    }

  },
  extraReducers: {}
});

export const {
  setVideo,
  setVisibility
} = videoModalStore.actions;