import React from 'react';

const IconQuestionSimple = ({size}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g id="Group-4" transform="translate(3.000000, 3.000000)" stroke="currentColor" strokeWidth="2.9583">
                    <path d="M11,12.0045262 C11.6505131,10.2088025 13.4082449,9 15.3687598,9 C17.9093408,9 20,11.0299045 20,13.496656 C20,13.4988853 20,13.5011147 20,13.503344 C20,16.5011822 15.3687598,18 15.3687598,18" id="Stroke-2" />
                    <line x1="15.4926" y1="23.5" x2="15.5074" y2="23.5" id="Stroke-3" />
                </g>
            </g>
        </svg>
    );
};

export default IconQuestionSimple;
