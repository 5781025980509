import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';
import { serializeError } from 'serialize-error';

const COUNTRIES_URL = "/api/countries";

export const fetchCountryCodes = createAsyncThunk(
    'countries/fetch',
    async (args, { rejectWithValue }) => {
        try {
            const res = await Axios.get(COUNTRIES_URL);
            const data = Object.keys(res.data).reduce((o, k) => {
                const v = res.data[k];
                if (!v.prefix) {
                    return {...o, [k]: {...v, prefix: ""}};
                }
                return {...o, [k]: v};
            }, {});
            return data;
        } catch (err) {
            if (err.isAxiosError){
                return rejectWithValue(serializeError(err.response));
            }
            throw err;
        }
    }
);

function sortCountries(countries) {
    return Object.keys(countries)
        .reduce((ret, k) => {
            const v = countries[k];
            return [...ret, { code: k, ...v }]
        }, [])
        .sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });
}

export const countryCodesStore = createSlice({
    name: 'countries',
    initialState: {
        countries: {},
        sortedCountries: [],
        error: null
    },
    reducers: {},
    extraReducers: {
        [fetchCountryCodes.fulfilled]: (state, action) => {
            return {
                countries: action.payload,
                sortedCountries: sortCountries(action.payload),
                error: null
            }
        },
        [fetchCountryCodes.rejected]: (state, action) => {
            return {
                countries: {},
                sortedCountries: [],
                error: action.payload
            }
        }
    }
});

export const {
} = countryCodesStore.actions;
