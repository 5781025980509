import React from 'react';

const IconArrowDown = ({size}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <polygon id="Fill-1" fill="currentColor" points="1 10 5.12763664 10 18.4602002 22.0692311 30.7687965 10.5680622 35 10.5680622 18.4206826 26" />
            </g>
        </svg>
    );
};

export default IconArrowDown;
