import React from 'react';
import {Link} from 'react-router-dom'
import classNames from 'classnames';
const Button = ({
    linkTo = null,
    linkTarget = '',
    isSubmit = false,
    type = 'primary',
    variant = '',
    size = '',
    isWide,
    className = '',
    children,
    disabled = false,
    onClick = (ev) => {}
}) => {
    let buttonClasses = classNames(className,{
        'btn-primary': type === 'primary',
        'btn-secondary': type === 'secondary',
        'btn-light': type === 'light',
        'btn-dark': type === 'dark',
        'btn-red': type === 'red',
        'btn-link': type === 'link',
        'stroked':variant === 'stroked',
        'btn-withicon':variant === 'withicon',
        'medium': size === 'md', //extends to 180px
        'large': size === 'lg', //extends to 200px
        'small': size === 'sm', //minify to 120px
        'wide' : isWide,//extends to 100% of parent container
    });
    // console.log(typeof linkTo, linkTo);
    const renderBtn = ()=>{

        return (
            <button
                type={isSubmit?'submit':'button'}
                className={buttonClasses}
                onClick={onClick}
                disabled={disabled}
            >
                <span>{children}</span>
            </button>
        );
    }
    const renderLink = ()=>{
        if(typeof linkTo==='string' && linkTo.startsWith('/')){
            return (
                <Link
                    to={linkTo}
                    target={linkTarget}
                    className={buttonClasses}
                >
                    <span>{children}</span>
                </Link>
            );
        }else{
            return (
                <a href={linkTo} target={linkTarget} className={buttonClasses}>
                    <span>{children}</span>
                </a>
            )
        }
    }

    return(
        <>
            { linkTo ? renderLink() : renderBtn() }
        </>
    )
};

export default Button;
