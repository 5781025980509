import React from 'react';

const IconTime = ({size}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g id="Group-3" transform="translate(4.000000, 4.000000)" stroke="currentColor" strokeWidth="2">
                    <path d="M14,0 C21.72688,0 28,6.27312 28,14 C28,21.72688 21.72688,28 14,28 C6.27312,28 0,21.72688 0,14 C0,6.27312 6.27312,0 14,0 Z" id="Stroke-1" />
                    <polyline id="Stroke-2" points="14 6 14 14.25 20 17" />
                </g>
            </g>
        </svg>
    );
};

export default IconTime;
