import React from 'react';

const IconDownload = ({size}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g id="Group-4" transform="translate(2.000000, 2.000000)" stroke="currentColor" strokeWidth="2">
                    <path d="M32,21 L32,28.3333333 C32,30.3448667 30.3950222,32 28.4444444,32 L3.55555556,32 C1.60497778,32 0,30.3448667 0,28.3333333 L0,21" id="Stroke-1" />
                    <polyline id="Stroke-2" points="8 12 16.5 21 25 12" />
                    <line x1="16.5" y1="21" x2="16.5" y2="0" id="Stroke-3" />
                </g>
            </g>
        </svg>
    );
};

export default IconDownload;
