import React, {useState, useRef, useEffect} from 'react';
import classNames from 'classnames';
import Button from "../../../core/components/Button";
import {useDispatch, useSelector} from "react-redux";
import WipIcon from "../../../core/components/WipIcon";
import {fetchData} from "../store/exhibitionGallery.store";
import {Link} from "react-router-dom";
import {setOpenTextModal,setContent} from "../../../core/store/textModal.slice";
import {selectLocale} from "../../../core/store/selectors/locales.selectors";

const ExhibitionSplash = ({onNavItemClick=(ev)=>{}}) => {
    let {navigation, trans} = useSelector(s=>s.exhibitionGallery);
    let locale = useSelector(selectLocale)
    const splashRef = useRef();
    let dispatch = useDispatch();

    let schools = useSelector(s=>s.exhibitionGallery.schools);

    const showIntro = ()=>{
        // setShowIntro(!showIntro);
        dispatch(setContent({
            title: trans?.label,
            htmlBody: trans?.content,
            author: trans?.author,
            authorRole: trans?.author_role,
        }));
        dispatch(setOpenTextModal(true))
    }

    useEffect(()=>{
        dispatch(fetchData());
    },[locale]);

    return (
        <div ref={splashRef} className={classNames('c-exhibition-splash',{'intro-on':false})}>
            {schools.length > 0 &&
                <div className="exhibition-splash__sidenav">
                    {schools.map((sc,i)=>{
                        const sl = sc.slides[0];
                        return(
                            <div
                                key={i}
                                 className={classNames('exhibition-splash__sidenav__item',{
                                     'current': i === navigation.y,
                                 })}
                            >
                                <Link to={`/exhibition-gallery/${sl.id}`}>{sc.name}</Link>
                            </div>
                        )
                    })}
                </div>
            }
            <div className="exhibition-splash__wrapper">
                <div className="exhibition-splash__pretitle">
                    {trans?.pretitle}
                </div>
                <div className="exhibition-splash__big-title">
                    {trans?.big_title}
                </div>
                <div className="exhibition-splash__title">
                    {trans?.title}
                </div>
                <div className="exhibition-splash__abstract">
                    {trans?.abstract}
                </div>
                <div className="exhibition-splash__description">
                    {trans?.description}
                </div>
                <div className="exhibition-splash__intro-trigger" onClick={showIntro}>
                    {trans?.intro_trigger}
                </div>

                <div className="exhibition-splash__cta">
                    <Button type={'light'} variant={'stroked'} size={'md'} linkTo={'/exhibition-gallery'}>
                        {trans?.cta}
                    </Button>
                </div>
            </div>

            {/*{showIntro &&*/}
            {/*    <div className="exhibition-splash__intro-modal">*/}
            {/*        <div className="exhibition-splash__intro-modal__wrapper">*/}
            {/*            <div className="exhibition-splash__intro-modal__close" onClick={showIntro}>*/}
            {/*                <WipIcon type={'close'}/>*/}
            {/*            </div>*/}
            {/*            <div className="exhibition-splash__intro-modal__label">*/}
            {/*                {trans?.label}*/}
            {/*            </div>*/}
            {/*            <div className="exhibition-splash__intro-modal__content">*/}
            {/*                <p>*/}
            {/*                    {trans?.content}*/}
            {/*                </p>*/}
            {/*                <p className="exhibition-splash__intro-modal__author">*/}
            {/*                    {trans?.author}*/}
            {/*                </p>*/}
            {/*                <p className="exhibition-splash__intro-modal__author-role">*/}
            {/*                    {trans?.author_role}*/}
            {/*                </p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*}*/}
        </div>
    );
};

export default ExhibitionSplash;
