// import { store } from '../../store';
export const selectLocale = state=> state.locale.current;
export const selectLocalesArr = state => state.locale.admitted;
export const getTransUi = state => state.locale.trans.ui;
export const getTransHome = state => state.locale.trans.home;
export const getTransConferenceStream = state => state.locale.trans.conference_stream;
export const getTransExhibition = state => state.locale.trans.exhibition;
export const getTransContact = state => state.locale.trans.contact;
export const getTransProfile = state => state.locale.trans.profile;
export const getTransRegister = state => state.locale.trans.register;
export const getTransResetPassword = state => state.locale.trans.resetPassword;
export const getTransLogin = state => state.locale.trans.login;
export const getTransNav = state => state.locale.trans.nav;
export const getPartnersLogos = state => state.locale.partnersLogos;
export const selectBackgrounds = state => state.locale.trans.backgrounds;
