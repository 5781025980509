import React from 'react';

const IconView = ({size}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group-3" transform="translate(1.000000, 8.000000)" fill="currentColor">
                    <path d="M32.7903665,9.85911513 C32.4954588,9.45649927 25.4712219,0 16.4999151,0 C7.52860826,0 0.503918552,9.45649927 0.209463701,9.85877604 C-0.0698212338,10.2407073 -0.0698212338,10.7588406 0.209463701,11.1408849 C0.503918552,11.5435007 7.52860826,21 16.4999151,21 C25.4712219,21 32.4954588,11.5433877 32.7903665,11.141224 C33.0698778,10.7592927 33.0698778,10.2407073 32.7903665,9.85911513 Z M16.4999151,18.8275472 C9.89149239,18.8275472 4.16801916,12.5511707 2.47374339,10.4992088 C4.1658682,8.44555143 9.87734135,2.17245277 16.4999151,2.17245277 C23.107885,2.17245277 28.8310186,8.44781205 30.5259736,10.5007912 C28.833962,12.5543355 23.1223756,18.8275472 16.4999151,18.8275472 Z" id="Fill-1"/>
                    <path d="M16.5000564,4 C12.9160069,4 10,6.9160069 10,10.4999436 C10,14.0839931 12.9160069,17 16.5000564,17 C20.0839931,17 23,14.0839931 23,10.4999436 C23,6.9160069 20.0839931,4 16.5000564,4 Z M16.5000564,14.8332018 C14.1105022,14.8332018 12.1667982,12.8893851 12.1667982,10.4999436 C12.1667982,8.1106149 14.1106149,6.16668546 16.5000564,6.16668546 C18.8893851,6.16668546 20.8333145,8.1106149 20.8333145,10.4999436 C20.8333145,12.8893851 18.8894978,14.8332018 16.5000564,14.8332018 Z" id="Fill-2"/>
                </g>
            </g>
        </svg>
    );
};

export default IconView;
