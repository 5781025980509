import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { events } from '@js/services/serviceEvents';

export const getEvents = createAsyncThunk(
    'events/getEvents',
    async (event, thunkAPI)=>{

        const resp = await events.getAll();
        return resp.data;
    }
);


let initialState = {
    events:[],
    programsTabVisible:false,
}

export const eventsStore = createSlice({
    name: 'events',
    initialState,
    reducers: {
        setProgramsVisible(state, {payload}) {
            state.programsTabVisible = payload;
        },
    },
    extraReducers: {
        [getEvents.fulfilled]: (state,action)=>{
            state.events= [...action.payload];
        }
    }
});

export const {
    setProgramsVisible
} = eventsStore.actions;
