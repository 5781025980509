import React,{useState,useEffect} from 'react';

const Backgrounds = ({location}) => {
    let sources = window.ASSET_BG;
    let [srcType,setSrcType] = useState('');
    useEffect(()=>{
        if(location.pathname.startsWith('/exhibition-gallery')){
            setSrcType('exhibition');
        }
        if(location.pathname.startsWith('/pages/conference')){
            setSrcType('exhibition');
        }
        // console.log(location);
    },[location])
    return (
        <picture className={'main-cover-bg'}>
            <source media="(min-width: 768px)" srcSet={sources?.[srcType]?.desktop}/>
            <source media="(max-width: 767px)" srcSet={sources?.[srcType]?.mobile}/>
            <img src={sources?.[srcType]?.desktop} alt="bg-cover"/>
        </picture>
    );
};

export default Backgrounds;
