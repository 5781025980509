import {createSlice} from '@reduxjs/toolkit';

let initialState = {
  cookieAccepted: true,
};

export const cookiebarStore = createSlice({
  name: 'cookiebar',
  initialState,
  reducers: {
    setCookie(state, action) {
      let deadline = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        new Date().getDate()
      );
      document.cookie = `cookieAcceptance=${action.payload}; expires=${deadline}`;
      state.cookieAccepted = action.payload;
    },
    checkCookieAcceptance(state, action) {
      // if cookie
      let value;
      if (document.cookie.split(';').some((item) => item.includes('cookieAcceptance=true'))) {
        state.cookieAccepted = true;
      } else {
        state.cookieAccepted = false;
      }
    },

  },
  extraReducers: {}
});

export const {
  setCookie,
  checkCookieAcceptance
} = cookiebarStore.actions;
