// import 'core-js/stable/object/assign';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './locale-strings-igniter';
import './cmsSiteSettings';
import './csrfTokenInterceptors';
import './phazerTicker';
import '@scss/app.scss';

ReactDOM.render(<App/>, document.getElementById('root'));
