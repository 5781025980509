import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect, useLocation, useHistory} from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from './core/store';

import { fetchCountryCodes } from './core/store/countryCodes.slice';
import { fetchUserData } from './core/store/user.slice';

import ScrollToTop from './core/components/ScrollToTop';
import Loading from './core/components/Loading';
import PrivateRoute from './core/components/PrivateRoute';
import ScrollObserver from "./core/components/ScrollObserver";
import LoginStatusSwitch from "./core/components/LoginStatusSwitch";
import ExhibitionSplash from "./views/exhibition/components/ExhibitionSplash";
import TextModal from "./core/components/TextModal";
import VideoModal from "./core/components/VideoModal";
import Backgrounds from "./core/components/Backgrounds";
import {getTransRegister} from "./core/store/selectors/locales.selectors";
import Img360Viewer from "./core/components/Img360Viewer";
import CmsPhase from "./core/components/CmsPhase";
// import Modal from "./core/components/Modal";
// import ChatWee from "./core/components/ChatWee";
// import ChatWrapper from "./core/components/ChatWrapper";
// import VideoBg from "./core/components/VideoBg";

const VideoBg = lazy(() => import('./core/components/VideoBg'));
const Home = lazy(() => import('./views/home'));
const CmsPage = lazy(() => import('./views/cms-page'));
// const ResetPasswordHeader = lazy(() => import('./views/resetPassword/components/ResetPasswordHeader'));
// const ResetPassword = lazy(() => import('./views/resetPassword'));
// const ProfileHeader = lazy(() => import('./views/profile/components/ProfileHeader'));
// const Profile = lazy(() => import('./views/profile'));
// const Login = lazy(() => import("./core/components/Login"));
// const CreateAccountStepper = lazy(() => import( './core/components/CreateAccountStepper'));
// const WipSideBar = lazy(() => import( "./core/components/WipSideBar"));
// const SplashWelcome = lazy(() => import( "./core/components/SplashWelcome"));
// const ChatWeeMobileCloser = lazy(() => import( "./core/components/ChatWeeMobileCloser"));
const ThankYou = lazy(() => import('./views/thankyou'));
const NotFound = lazy(() => import('./core/components/NotFound'));
const Contact = lazy(() => import('./views/contact'));
const HeaderNavs = lazy(() => import("./core/components/HeaderNavs"));
const Footer = lazy(() => import( './core/components/Footer'));
const Cookiebar = lazy(() => import( "./core/components/Cookiebar"));
const Concert = lazy(() => import('./views/concert'));
const Exhibition = lazy(() => import("./views/exhibition"));

function App() {
    const [activeVideoBg,setActiveVideBg] = useState(true);
    const [withHeader,setWithHeader] = useState(true);
    const [withFooter,setWithFooter] = useState(true);
    const [regModal,setRegModal] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const tRegister = useSelector(getTransRegister);
    const user = useSelector(state => state.user);
    const currentLocale = useSelector(state => state.locale.current);
    const {headerVisible,headerConference} = useSelector(state=>state.headerNavs);
    const {openViewer,current} = useSelector(s=>s.img360);

    useEffect(()=>{

        //no header no footer paths
        let pathsList = [
            '/exhibition-gallery',
        ];
        let bodyClass = 'clean';
        pathsList.forEach(p=>{
            if(location.pathname.startsWith(p)){
                console.log(location.pathname);
                setWithHeader(false);
                setWithFooter(false);
                setActiveVideBg(false);
                document.body.classList.add(bodyClass);
            }else{
                document.body.classList.remove(bodyClass);
                setWithHeader(true);
                setWithFooter(true);
                setActiveVideBg(true);
            }
        });
        let confPath = '/pages/conference';
        if(location.pathname.startsWith(confPath)){
            setActiveVideBg(false);
        }
        // if (pathsList.indexOf(location.pathname) !== -1){
        //     console.log(location.pathname);
        //     setWithHeader(false);
        //     setWithFooter(false);
        //     setActiveVideBg(false);
        //     document.body.classList.add(bodyClass);
        // }else{
        //     document.body.classList.remove(bodyClass);
        //     setWithHeader(true);
        //     setWithFooter(true);
        //     setActiveVideBg(true);
        // }
    },[location])
    const handleRegModal = ()=>{
        setRegModal(false);
    }
    useEffect(() => {
        dispatch(fetchCountryCodes());
    }, []);


    useEffect(() => {
        const whitelist = [
            '/pages/privacy-policy',
            '/pages/cookie-policy'
        ];
        if (whitelist.indexOf(location.pathname) !== -1) {
            return;
        }

        if (user.status === "MUST_SET_PASSWORD" &&
            !location.pathname.startsWith('/register/password')) {
            history.replace(`/register/password`);
            return;
        }
        if (user.status === "MUST_COMPLETE_SIGNUP" &&
            !location.pathname.startsWith('/register/profile')) {
            history.replace('/register/profile');
            return;
        }
    }, [user]);

    //SplashWelcome need manual scrollRestoration when home. Will be restored to auto when unmounted
    useEffect(()=>{

        let hasScrollRestoration = 'scrollRestoration' in window.history;
        if(location.pathname === '/' && !user.logged ){
            if(hasScrollRestoration){
                window.history.scrollRestoration = 'manual';
            }
        }else{
            window.history.scrollRestoration = 'auto';
        }
    },[user])

    return (
        <>
            <Suspense fallback={<Loading/>}>
                {activeVideoBg && <VideoBg autoplay={true} loop={true} muted={true}/>}
                {!activeVideoBg && <Backgrounds location={location}/>}
                <div className="l-main-content">
                    {withHeader && <HeaderNavs visible={headerVisible} isConference={headerConference} />}
                    <Switch>
                        <Route path="/" exact={true}>
                            <Home/>
                        </Route>
                        <Route path="/exhibition-gallery/:slideId" exact={true}>
                            {({match}) => (
                                <CmsPhase slug="exhibition">
                                    <CmsPhase.Default>
                                        <Redirect to="/pages/exhibition"/>
                                    </CmsPhase.Default>
                                    <CmsPhase.Pre>
                                        <Redirect to="/pages/exhibition"/>
                                    </CmsPhase.Pre>
                                    <CmsPhase.Live>
                                        <Exhibition slideId={match.params.slideId}/>
                                    </CmsPhase.Live>
                                </CmsPhase>
                            )}
                        </Route>
                        <Route path="/exhibition-gallery" exact={true}>
                            <CmsPhase slug="exhibition">
                                <CmsPhase.Default>
                                    <Redirect to="/pages/exhibition"/>
                                </CmsPhase.Default>
                                <CmsPhase.Pre>
                                    <Redirect to="/pages/exhibition"/>
                                </CmsPhase.Pre>
                                <CmsPhase.Live>
                                    <Exhibition/>
                                </CmsPhase.Live>
                                <CmsPhase.Post>
                                    <Exhibition/>
                                </CmsPhase.Post>
                            </CmsPhase>
                        </Route>
                        <Route path="/pages/exhibition/:slideId" exact={true}>
                            {({match}) => (
                                <Redirect to={`/exhibition-gallery/${match.params.slideId}`}/>
                            )}
                        </Route>
                        <Route path="/pages/exhibition" exact={true}>
                            <CmsPhase slug="exhibition">
                                <CmsPhase.Default>
                                    <CmsPage slug="exhibition"/>
                                </CmsPhase.Default>
                                <CmsPhase.Pre>
                                    <CmsPage slug="exhibition"/>
                                </CmsPhase.Pre>
                                <CmsPhase.Live>
                                    <ExhibitionSplash/>
                                </CmsPhase.Live>
                                <CmsPhase.Post>
                                    <CmsPage slug="exhibition"/>
                                </CmsPhase.Post>
                            </CmsPhase>
                        </Route>
                        <Route path="/pages/exhibition-intro" exact={true}>
                            <CmsPhase slug="exhibition">
                                <CmsPhase.Default>
                                    <Redirect to="/pages/exhibition"/>
                                </CmsPhase.Default>
                                <CmsPhase.Pre>
                                    <Redirect to="/pages/exhibition"/>
                                </CmsPhase.Pre>
                                <CmsPhase.Live>
                                    <Redirect to="/pages/exhibition"/>
                                </CmsPhase.Live>
                                <CmsPhase.Post>
                                    <ExhibitionSplash/>
                                </CmsPhase.Post>
                            </CmsPhase>
                        </Route>
                        <Route path="/pages/concert" exact={true}>
                            <CmsPhase slug="concert">
                                <CmsPhase.Default>
                                    <CmsPage slug="concert"/>
                                </CmsPhase.Default>
                                <CmsPhase.Pre>
                                    <CmsPage slug="concert"/>
                                </CmsPhase.Pre>
                                <CmsPhase.Live>
                                    <Concert/>
                                </CmsPhase.Live>
                                <CmsPhase.Post>
                                    <CmsPage slug="concert"/>
                                </CmsPhase.Post>
                            </CmsPhase>
                        </Route>
                        <Route path="/concert" exact={true}>
                            <CmsPhase slug="concert">
                                <CmsPhase.Default>
                                    <Redirect to="/pages/concert"/>
                                </CmsPhase.Default>
                                <CmsPhase.Pre>
                                    <Redirect to="/pages/concert"/>
                                </CmsPhase.Pre>
                                <CmsPhase.Live>
                                    <Redirect to="/pages/concert"/>
                                </CmsPhase.Live>
                                <CmsPhase.Post>
                                    <Concert/>
                                </CmsPhase.Post>
                            </CmsPhase>
                        </Route>
                        <Route path="/pages/:slug" exact={true}>
                            {({match}) => (
                                <CmsPage slug={match.params.slug}/>
                            )}
                        </Route>
                        <Route path="/contact" exact={true}>
                            <Contact/>
                        </Route>
                        <Route path="/contact/thank-you" exact={true}>
                            <ThankYou variant="contact"/>
                        </Route>
                        <Route path="/404" component={NotFound} />
                        <Redirect to="/404" />
                    </Switch>
                </div>
                {withFooter && <Footer/>}
                <Cookiebar/>
                <TextModal/>
                <VideoModal/>
                {openViewer && <Img360Viewer {...current}/>}
            </Suspense>
        </>
    );
}

export default function Wrapp() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                {/*<ScrollToTop />*/}
                <App/>
            </BrowserRouter>
        </Provider>
    )
}
