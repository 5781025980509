import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {settings} from "@js/services/serviceSettings";

export const getConferenceSettings = createAsyncThunk(
    'conference/getSettings',
    async (arg,thunkAPI)=>{

        const resp = await settings.conference();
        return resp.data;
    }
);



export const conferenceStore = createSlice({
    name: 'conference',
    initialState: {
        loaded:false,
        settings:{},
    },
    reducers: {
        test(state,payload) {
            state.page = test;
        }
    },
    extraReducers: {
        [getConferenceSettings.pending]:(state,action)=>{
            state.loaded = false;
        },
        [getConferenceSettings.fulfilled]:(state,action)=>{
            state.settings = {...action.payload.settings};
            state.loaded = true;
        },

    }
});

export const {test}= conferenceStore.actions;
