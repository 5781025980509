import React from 'react';

const IconEmail = ({size}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g id="Group-3" transform="translate(2.000000, 5.000000)" stroke="currentColor" strokeWidth="2">
                    <path d="M2.6,0 L23.4,0 C24.83,0 26,1.125 26,2.5 L26,17.5 C26,18.875 24.83,20 23.4,20 L2.6,20 C1.17,20 0,18.875 0,17.5 L0,2.5 C0,1.125 1.17,0 2.6,0 Z" id="Stroke-1"/>
                    <polyline id="Stroke-2" points="26 2 13 11 0 2"/>
                </g>
            </g>
        </svg>
    );
};

export default IconEmail;
