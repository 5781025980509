import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchData} from "../../views/exhibition/store/exhibitionGallery.store";

/*
* instance Ex:
* resources = {
*   id: some id,
*   src: source img
* }
* */
let initialState = {
    resources:[],
    current:null,
    openViewer:false,
    exhibitionLoaded:false,
}

export const img360Store = createSlice({
    name: 'img360',
    initialState,
    reducers: {
        setOpenViewer(state, action) {
            state.openViewer = action.payload;
        },
        setToStage360(state,action){
            let isPresent = state.resources.findIndex(el=>el.id === action.payload.id);
            console.log(isPresent);
            if(isPresent === -1){
                state.resources.push(action.payload);
                state.current = action.payload;
            }else{
                state.current = state.resources[isPresent];
            }
            state.openViewer = true;
        }

    },
    extraReducers: {
        [fetchData.fulfilled]: (state,action)=>{
            state.exhibitionLoaded= true
        }
    }
});

export const {
    setOpenViewer,
    setToStage360
} = img360Store.actions;
