import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';
import { serializeError } from 'serialize-error';

const CHANGE_PASSWORD_URL = '/user/change-password';

export const confirm = createAsyncThunk(
    'resetPasswordForm/resetPassword',
    async (args, { getState, rejectWithValue }) => {
        const { password, confirmPassword } = getState().resetPasswordForm;
        try {
            const res = await Axios.post(CHANGE_PASSWORD_URL, {
                password,
                confirmPassword
            });
            return res.data;
        } catch (err) {
            if (err.isAxiosError) {
                return rejectWithValue(serializeError(err));
            }
            throw err;
        }
    }
);

const initialState = () => {
    return {
        password: "",
        confirmPassword: "",
        errors: {}
    }
};

export const resetPasswordFormStore = createSlice({
    name: 'resetPasswordForm',
    initialState: initialState(),
    reducers: {
        setFormData(state, action) {
            const { password, confirmPassword } = action.payload;
            const errors = {};

            if (password && confirmPassword && password !== confirmPassword) {
                errors.passwordMismatch = "passwords do not match"
            }

            return {
                password,
                confirmPassword,
                errors
            }
        }
    },
    extraReducers: {
        [confirm.fulfilled]: (state, action) => {
            return initialState();
        },
        [confirm.rejected]: (state, action) => {
            console.log(action)
            const {
                response: {
                    status,
                    data: {
                        errors: err
                    },
                }
            } = action.payload;

            const errors = Object.keys(err)
                .reduce((acc, k) => ({ ...acc, [k]: err[k][0]}), {});
            if (status === 422) {
                state.errors = {...state.errors, ...errors};
            }
        }
    }
});

export const {
    setFormData,
} = resetPasswordFormStore.actions;