import React from 'react';

const IconLunch = ({size}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group-4" transform="translate(15.000000, 15.000000) rotate(-360.000000) translate(-15.000000, -15.000000) translate(1.000000, 1.000000)" fill="currentColor">
                    <path d="M27.2916085,23.8068604 L16.4891149,12.8274749 L3.86822538,-2.84217094e-14 L2.88189554,-2.84217094e-14 L2.68049723,0.520131794 C2.14894126,1.89300411 1.92182336,3.29715761 2.02378697,4.58061603 C2.14000935,6.04361317 2.68684641,7.31022788 3.60527224,8.24368699 L13.4578093,18.2574955 L14.7573476,16.9366856 L23.8660076,27.2886427 C24.7721117,28.2095784 26.3316977,28.2642658 27.2916085,27.2886427 C28.2361305,26.3286602 28.2361305,24.7667882 27.2916085,23.8068604 Z" id="Fill-1"/>
                    <path d="M8.5311091,16 L0.717250648,23.817473 C-0.239083549,24.7742476 -0.239083549,26.3311322 0.717250648,27.2879067 C1.62574634,28.1968208 3.19738832,28.2770608 4.18608706,27.2879067 L12,19.4703793 L8.5311091,16 Z" id="Fill-2"/>
                    <path d="M26.8364681,4.65390811 L22.3764729,9.11384857 L21.212941,7.95037154 L25.6729363,3.49037626 L24.5094593,2.32689923 L20.0495188,6.78683969 L18.8860418,5.62336266 L23.3460371,1.1634222 L22.1826149,-2.84217094e-14 L16.3652297,5.81738514 C15.6535581,6.52905677 15.2286165,7.47400494 15.1685293,8.47827279 C15.153343,8.73271143 15.0956681,8.98298344 15,9.22102968 L18.7789703,13 C19.0170166,12.9042223 19.2673434,12.8466022 19.5217272,12.8314159 C20.5259951,12.7714932 21.4709432,12.3464419 22.1826149,11.6347703 L28,5.81743997 L26.8364681,4.65390811 Z" id="Fill-3"/>
                </g>
            </g>
        </svg>
    );
};

export default IconLunch;
