import { createSlice } from '@reduxjs/toolkit';

let initialState = {

}

export const sidebarStore = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {

    },
    extraReducers: {

    }
});

export const {

} = sidebarStore.actions;
