import React, {useRef, useState, useEffect} from 'react';

const ScrollObserver = ({children,checkVisibility=false,showOnce=false}) => {


  const [isActive, setIsActive] = useState(false);
  const [hide, setHide] = useState(false);
  const ref = useRef();

  const onVisible = (bool) => {
    setIsActive(bool);
  };

  useEffect(() => {
      const observer = new IntersectionObserver(
          ([entry]) => {
              // console.log(entry);
              if(showOnce){
                  // console.log(entry);
              }

              if (entry.intersectionRatio > 0) {
                  setIsActive(true);
              }

              if(checkVisibility && entry.intersectionRatio <= 0.1){
                  setIsActive(false);
              }

              if(showOnce && !entry.isIntersecting  && entry.boundingClientRect.bottom < 0){
                  setHide(true);
              }
          }, {
              root: null,
              rootMargin: '0px',
              threshold: [0,0.1,0.7,1]
          }
      );

      if (ref.current) {
          observer.observe(ref.current)
      }

  },[]);

  let activeClass = (isActive) ? 'in-view' : 'out-view';
  return <div ref={ref} className={activeClass}>{!hide?children:null}</div>

};

export default ScrollObserver;
