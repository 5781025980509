import React from 'react';

const IconCheck = ({size}) => {
  return (
    <svg version="1.1" width={size} height={size} viewBox="0 0 30 30" enableBackground="new 0 0 30 30">
      <g>
        <path fillRule="evenodd" clipRule="evenodd" fill="currentColor" d="M26.8,9c0.9,1.8,1.4,3.8,1.4,6c0,7.6-6.2,13.8-13.8,13.8
		C6.8,28.8,0.6,22.6,0.6,15c0-7.6,6.2-13.8,13.8-13.8c5.1,0,9.6,2.8,12,6.9l2.2-2.2c0.2-0.2,0.5-0.2,0.7,0c0.2,0.2,0.2,0.5,0,0.7
		L26.8,9z M25.7,8.8c-2.2-4-6.4-6.7-11.3-6.7C7.3,2.1,1.6,7.9,1.6,15c0,7.1,5.8,12.9,12.9,12.9c7.1,0,12.9-5.8,12.9-12.9
		c0-1.9-0.4-3.7-1.1-5.3L14.5,21.4l-9.2-9.2c-0.2-0.2-0.2-0.5,0-0.7c0.2-0.2,0.5-0.2,0.7,0l8.6,8.6L25.7,8.8L25.7,8.8z"/>
      </g>
    </svg>

  );
};

export default IconCheck;
