import React from 'react';

const IconChat = ({size}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group-5" transform="translate(5.000000, 5.000000)">
                    <path d="M25.5871,15.6612 L21.3332,11.4073 L11.4073,11.4073 C10.6295,11.4073 9.9894,10.7672 9.9894,9.9893 L9.9894,1.4814 C9.9894,0.7036 10.6295,0.0635 11.4073,0.0635 L24.1692,0.0635 C24.947,0.0635 25.5871,0.7036 25.5871,1.4814 L25.5871,15.6612" id="Stroke-2" stroke="currentColor" strokeWidth="2.127" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M15.6613,17.0792 L15.6613,19.9152 C15.6613,20.6931 15.0212,21.3332 14.2433,21.3332 L4.3174,21.3332 L0.0635,25.5871 L0.0635,11.4073 C0.0635,10.6294 0.7036,9.9893 1.4815,9.9893 L4.3174,9.9893" id="Stroke-4" stroke="currentColor" strokeWidth="2.127" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
            </g>
        </svg>
    );
};

export default IconChat;
