import React from 'react';

const IconArrowSx = ({size}) => {
    return (
    <svg width={size} height={size} viewBox="0 0 25 37" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Tavola-da-disegno1" transform="matrix(1,0,0,1,-695.017,-586.596)" style={{fillRule:"evenodd",clipRule:"evenodd",strokeLinejoin:"round",strokeMiterlimit:2}}>
            <rect x="695.017" y="586.596" width="24.332" height="36.617" style={{fill:"none"}}/>
            <g transform="matrix(0.515927,6.31829e-17,6.31829e-17,-0.515927,-2961.44,4070.95)">
                <path d="M7117.46,6717.98L7092.18,6696.15C7090.56,6694.75 7090.38,6692.3 7091.78,6690.68C7093.18,6689.06 7095.63,6688.88 7097.25,6690.28L7128.84,6717.57L7100.7,6745.7C7099.19,6747.22 7096.73,6747.22 7095.22,6745.7C7093.71,6744.19 7093.71,6741.74 7095.22,6740.22L7117.46,6717.98Z" fill="currentColor"/>
            </g>
        </g>
    </svg>

    );
};

export default IconArrowSx;