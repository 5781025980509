import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

function UTC(year, month, day, hour, minute) {
   return Date.UTC(year, month - 1, day, hour, minute);
}

const PHASES = [
    // UTC(year, month, day, hour, minute)
    {
        name: ""
    },
    {
        name: "pre",
        to: UTC(2020, 10, 2, 15, 0)
    },
    {
        name: "event",
        from: UTC(2020, 10, 2, 15, 0),
        to: UTC(2020, 10, 2, 15, 30)
    },
    {
        name: "post",
        from: UTC(2020, 10, 2, 15, 30)
    },
];

function getPhase(now) {
    let ret;
    for (let phase of PHASES) {
        let ok = true;
        if (phase.from) {
            ok = ok && now >= phase.from;
        }
        if (phase.to) {
            ok = ok && now <= phase.to;
        }
        if (ok) {
            ret = {...phase};
        }
    }
    return ret
}

function hydrate() {
    const now = (new Date()).getTime();
    const phase = getPhase(now);
    return {
        now,
        phase
    }
}

export const phazerStore = createSlice({
    name: 'phazer',
    initialState: hydrate(),
    reducers: {
       tick() {
           return hydrate()
       }
    }
});

export const { tick } = phazerStore.actions;
